



















import { Vue, Component, Watch } from 'vue-property-decorator';
import { Action, State, namespace } from 'vuex-class';
import OrderItem from './components/OrderItem.vue';
import { RootState } from '@/store/store';
import Container from '@/components/Container.vue';
import Card from '@/components/Card.vue';
import { ApiState } from '../../store/types/general.types';
import { AxiosResponse } from 'axios';
import { Order } from '@/store/modules/order/order.types';

@Component({
  components: {
    OrderItem,
    Container,
    Card
  }
})
export default class MyOrdersPage extends Vue {
  @Action('order/getMyOrders')
  public getMyOrders!: () => void;

  @State((state: RootState) => state.order.orders)
  public orders!: Order[];

  @State((state: RootState) => state.order.apiState.getMyOrders)
  public getMyOrdersState!: ApiState;

  @Action('order/retryPayment')
  public retryPayment!: (orderId: number) => Promise<AxiosResponse | any>;

  get isLoading() {
    return this.getMyOrdersState.loading;
  }

  public mounted() {
    this.getMyOrders();
  }

  public handleRetryPayment(order: Order) {
    return this.retryPayment(order.paymentId).then((data) => {
      if (data && data.paymentRequired) {
        const authKey = data.AuthKey;
        return (window.location.href = `${process.env.VUE_APP_BPOINT_REDIRECT_PAYMENT_URL}?in_pay_token=${authKey}`);
      }
    });
  }
}
