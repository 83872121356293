





















































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';
import dayjs from 'dayjs';
import Card from '@/components/Card.vue';
import { Order } from '@/store/modules/order/order.types';

@Component({
  components: {
    Card
  }
})
export default class OrderItem extends Vue {
  @Prop()
  public order: Order | undefined;

  get dayjs() {
    return dayjs;
  }

  get orderDate() {
    return this.order?.updatedAt;
  }

  public navigateToOrderPage(orderId: number) {
    return this.$router.push({ name: 'Order', params: { id: orderId + '' } });
  }
}
